/* eslint-disable react/no-danger */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
import Calendar from '../../page-index/CalendarDashbaord';

// UI local component
import PageTitle from '../../page-title/index';

// Style
import './index.scss';
import 'react-calendar/dist/Calendar.css';

/* -------------------------------------------------------------------------- */
/*                                  Calendar                                  */
/* -------------------------------------------------------------------------- */

function CalendarSection() {
  /* ****************************** RENDERING ******************************* */
  return (
    <Container className="calendar-wrapper fluid" fluid>
      <Container className="title">
        <PageTitle title="Evènements" />
      </Container>
      <Container className="calendar-box">
        <Container className="calendar-container-box fluid" fluid>
          <Calendar showAllEvents={false} showNumberOfEvents />
        </Container>
      </Container>
    </Container>
  );
}

export default CalendarSection;
