/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// ProtoTYpes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Local Links
import eventsMenuLinks from '../../local-links/pageAllEvents/menuLinks';

// Style
import '../../../page-components/page-all-events-components/fliterEvents/index.scss';

/* -------------------------------------------------------------------------- */
/*                            Actualities Bloc                                */
/* -------------------------------------------------------------------------- */

function EventsMenu({ setValueButton }) {
  /* ------------------------------ CONST ---------------------------------- */
  const eventsNavbar = eventsMenuLinks.map((action) => {
    return (
      <button
        className="menu-button flex items-center justify-center"
        type="submit"
        value={action.button}
        onClick={() => setValueButton(action.button)}
      >
        {action.button}
      </button>
    );
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="events-menu flex flex-wrap fluid" fluid>
      {eventsNavbar}
    </Container>
  );
}

EventsMenu.propTypes = {
  setValueButton: PropTypes.func.isRequired,
};

export default EventsMenu;
