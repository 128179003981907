const eventsMenuLinks = [
  {
    id: 1,
    button: 'Webinaire',
  },
  {
    id: 2,
    button: 'Infoday',
  },
];
export default eventsMenuLinks;
