/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import CalendarSection from '../page-components/page-all-events-components/calendar/index';
import EventsMenu from '../shared/constants/PageAllEvents/meuEvents';
import FiltredEvents from '../page-components/page-all-events-components/fliterEvents/index';

// API link
import API, { JALIOS_EVENTS_BY_TYPE, JALIOS_EVENTS } from '../shared/APIs';

// Style
import '../page-styles/events.scss';
import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                             All Events  Page                               */
/* -------------------------------------------------------------------------- */

function AllEventsPage({ location }) {
  /* ------------------------------ Hooks ----------------------------------- */
  const [JaliosInfoDayData, setJaliosInfoDayData] = useState([]);
  const [JaliosWebinaireData, setJaliosWebinaireData] = useState([]);
  const [JaliosAllEventsData, setJaliosAllEventsData] = useState([]);
  const [eventsData, setEventsData] = useState();
  const [valueButton, setValueButton] = useState('');
  const [loader, setLoader] = useState(true);

  /* ------------------------------ HELPERS --------------------------------- */
  // GET JALIOS ALL EVENTS
  async function fetchJaliosAllEvents() {
    try {
      const { data } = await axios(JALIOS_EVENTS, {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosAllEventsData(() => {
        return data.dataSet;
      });
    } catch (error) {
      setJaliosAllEventsData([]);
    }
  }

  // GET JALIOS WEBINAIRE EVENTS
  async function fetchJaliosEventsWebinaire() {
    try {
      const { data } = await axios(JALIOS_EVENTS_BY_TYPE('2'), {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosWebinaireData(() => {
        return data.dataSet;
      });
    } catch (error) {
      setJaliosWebinaireData([]);
    }
  }

  // GET JALIOS INFODAY EVENTS
  async function fetchJaliosEventsInfoday() {
    try {
      const { data } = await axios(JALIOS_EVENTS_BY_TYPE('1'), {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosInfoDayData(() => {
        return data.dataSet;
      });
    } catch (error) {
      setJaliosInfoDayData([]);
    }
  }

  // FETCH EVENTS
  useEffect(() => {
    axios
      .get(API('events'))
      .then(({ data: { data } }) => {
        setEventsData(data);
        setLoader(true);
      })
      .catch((error) => error)
      .finally(() => setLoader(false));
    fetchJaliosAllEvents();
    fetchJaliosEventsInfoday();
    fetchJaliosEventsWebinaire();
  }, []);

  /* ******************************* RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Evénements" />
      <Container className="page-events fluid">
        <CalendarSection />
        <Container className="events-menu-container">
          <Visible lg xl xxl>
            <Container className="top flex items-center fluid">
              <Link className="breadcrumb__link" to="/">
                Accueil
              </Link>
              <span className="m-r-l">/</span>
              <Link
                className="breadcrumb__link"
                to={`/${location.pathname.split('/')[1]}`}
                onClick={() => setValueButton('')}
              >
                Évènements
              </Link>
            </Container>
          </Visible>
          {loader ? (
            <Container className="loader fluid" />
          ) : (
            <>
              <EventsMenu setValueButton={setValueButton} />
              <FiltredEvents
                eventsData={eventsData}
                valueButton={valueButton}
                JaliosAllEventsData={JaliosAllEventsData}
                jaliosWebinaire={JaliosWebinaireData}
                jaliosInfoday={JaliosInfoDayData}
              />
            </>
          )}
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

AllEventsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AllEventsPage;
