/* eslint-disable prefer-const */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Row } from 'react-grid-system';

// Assets
import CALENDAR_ICON from '../../../images/Events/calendar.svg';

// Style
import './index.scss';

/* ------------------------------------------------------------------------ */
/*                                  Events                                  */
/* ------------------------------------------------------------------------ */

function FiltredEvents({
  eventsData,
  valueButton,
  jaliosWebinaire,
  jaliosInfoday,
}) {
  /* ---------------------------------- HOOKS --------------------------------- */

  const [AlleventsJalios] = useState([...jaliosWebinaire, ...jaliosInfoday]);
  /* =================== STRAPI =================== */
  // FILTER STRAPI EVENTS DEPENDS ON THE FIRST WORD OF EVENT TITLE
  const WEBINAIRE_EVENTS_STRAPI = eventsData?.filter(
    (event) => event?.attributes?.title?.split(' ')[0] === 'Webinaire',
  );

  const INFODAY_EVENTS_STRAPI = eventsData?.filter(
    (event) => event?.attributes?.title?.split(' ')[0] !== 'Webinaire',
  );

  // GET STRAPI EVENTS
  function getEventsDataStrapi() {
    if (valueButton === 'Webinaire') {
      return WEBINAIRE_EVENTS_STRAPI;
    }
    if (valueButton === 'Infoday') {
      return INFODAY_EVENTS_STRAPI;
    }

    return eventsData;
  }
  const finalStrapiData = getEventsDataStrapi();

  /* =================== JALIOS =================== */
  // GET JALIOS EVENTS
  function getEventsDataJalios() {
    if (valueButton === 'Webinaire') {
      return jaliosWebinaire;
    }
    if (valueButton === 'Infoday') {
      return jaliosInfoday;
    }
    return AlleventsJalios;
  }
  const finalJaliosData = getEventsDataJalios();

  // render the selected events sorted by date

  const eventsStrapiPreSorted = finalStrapiData?.map(
    ({ attributes: { date } }, index) => {
      const timestamp = new Date(date).getTime();
      return { type: 'strapi', timestamp, index };
    },
  );
  const eventsJaliosPreSorted = finalJaliosData?.map(({ startDate }, index) => {
    const timestamp = new Date(startDate).getTime();
    return { type: 'jalios', timestamp, index };
  });
  let sortedEvents = [];
  if (
    eventsJaliosPreSorted !== undefined &&
    eventsStrapiPreSorted !== undefined
  ) {
    const eventsArray = [
      ...eventsJaliosPreSorted,
      ...eventsStrapiPreSorted,
    ].sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    sortedEvents.push(...eventsArray);
  }

  function renderAllSortedEvents() {
    return sortedEvents?.map(({ type, index }) => {
      if (type === 'strapi') {
        const event = finalStrapiData[index];
        const SPLITTED_DATE = event?.attributes?.date?.split('--');
        const STYLED_DATE = SPLITTED_DATE.reverse();
        return (
          <Link to="/details-event" state={{ Source: 'Strapi', event }}>
            <Container key={event.id} className="events-container fluid" fluid>
              <Container className="flex flex-wrap items-center mb-1 fluid">
                <Container className="flex items-center mr-1 fluid" fluid>
                  <img src={CALENDAR_ICON} alt="calendar icon" />
                  <h2 className="down">{STYLED_DATE}</h2>
                </Container>
              </Container>
              <Row className="mb-1 fluid">
                <h5>{event.attributes.title}</h5>
              </Row>
            </Container>
          </Link>
        );
      }
      if (type === 'jalios') {
        const event = finalJaliosData[index];
        const { title } = event;
        const FORMATED_EVENT_DATE = new Date(event.startDate);
        const STYLED_EVENT_DATE = format(FORMATED_EVENT_DATE, 'dd-MM-yyyy');
        return (
          <Link to="/details-event" state={{ Source: 'Jalios', event }}>
            <Container className="events-container fluid" fluid>
              <Container className="flex flex-wrap items-center mb-1 fluid">
                <Container className="flex items-center mr-1 fluid" fluid>
                  <img src={CALENDAR_ICON} alt="calendar icon" />
                  <h2 className="down">{STYLED_EVENT_DATE}</h2>
                </Container>
              </Container>
              <Row className="mb-1 fluid">
                <h5>{title}</h5>
              </Row>
            </Container>
          </Link>
        );
      }
    });
  }

  /* ****************************** RENDERING ******************************* */
  return (
    <Container className="events-wrapper fluid" fluid>
      <>{renderAllSortedEvents()}</>
    </Container>
  );
}
FiltredEvents.propTypes = {
  valueButton: PropTypes.string.isRequired,
  eventsData: PropTypes.objectOf(PropTypes.object).isRequired,
  JaliosAllEventsData: PropTypes.objectOf(PropTypes.object).isRequired,
  jaliosWebinaire: PropTypes.objectOf(PropTypes.object).isRequired,
  jaliosInfoday: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default FiltredEvents;
